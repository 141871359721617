@font-face {
  font-family: Calibri;
  src: url("assets/fonts/Calibri.ttf");
}

html,
body,
#root,
.app {
  display: flex;
  min-height: 100%;
  max-height: 100%;
  min-width: 100%;
  max-width: 100%;
  overflow: hidden;
}

body {
  margin: 0;
}

/*html {*/
/*     -ms-overflow-style: none;*/
/*}*/

/*::-webkit-scrollbar {*/
/*    display: none;*/
/*}*/

* {
  box-sizing: border-box;
  font-family: Calibri, HelveticaNeue, Arial, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

.main {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  width: 100%;
  overflow-x: hidden;
}

.content {
  overflow-y: scroll;
  padding-top: 20px;
  height: 100%;
}

.mt10 {
  margin-top: 10px;
}

.mt20 {
  margin-top: 20px;
}

.mt40 {
  margin-top: 40px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb40 {
  margin-bottom: 40px;
}

.capitalize {
  text-transform: capitalize;
}

.mainContainer {
  max-width: 1400px;
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-grow: 1;
  margin: 0 auto;
}

.mainContainerFullScreen {
  padding: 0  20px;
}

.pageContainer {
  flex-direction: column;
  display: flex;
  flex-grow: 1;

  min-width: 100%;
  max-width: 100%;

  min-height: fit-content;
}

.showXS,
.showSM,
.showMD,
.showLG,
.showXL,
.hideXXL {
  display: none !important;
}

@media screen and (max-width: 500px) {
  .showXS {
    display: flex !important;
  }

  .hideXS {
    display: none !important;
  }
}

@media screen and (max-width: 700px) {
  .showSM {
    display: flex !important;
  }

  .hideSM {
    display: none !important;
  }
}

@media screen and (max-width: 900px) {
  .showMD {
    display: flex !important;
  }

  .hideMD {
    display: none !important;
  }
}

@media screen and (max-width: 1100px) {
  .showLG {
    display: flex !important;
  }

  .hideLG {
    display: none !important;
  }
}

@media screen and (max-width: 1300px) {
  .showXL {
    display: flex !important;
  }

  .hideXL {
    display: none !important;
  }
}
